.title{
    font-size: 3rem;
}

.about-div{
    display: flex;
    justify-content: center;
    text-align: center;
}

.about-paragraph{
    max-width: 80%;
}

@media screen and (min-width: 600px){
    .title{
        font-size: 4rem;
    }
    .about-paragraph{
        font-size: 1.5rem;
    }
}
@media screen and (min-width: 1024px){
    .title{
        font-size: 5rem;
    }
    .about-paragraph{
        font-size: 2rem;
    }
}
@media screen and (min-width: 1366px){
    .about-paragraph{
        font-size: 3rem;
    }
}