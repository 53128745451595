.intro-wrapper{
    display: flex;
    flex-direction: column;
    text-align: center;
    animation: 1s ease-in-out .2s 1 both rise-up;
}

.text {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: 0rem 1rem;
}

.text h1{
    font-size: 2.4rem;
    margin: 0.3rem 0rem;
}

.text p{
    font-size: 1.3rem;
}

.small-pic-div{
    width: 100%;
}

.small-pic{
    height:225px;
    width:auto;
}

.large-pic-div{
    width: 100%;
    display: none;
}

.large-pic{
    max-height: 70vh;
    max-width: 100%;
    border-radius: 5px;
}

@keyframes rise-up {
    0%{
        transform: translateY(50px);
        opacity: 0;
    }
    100%{
        transform: translateY(0px);
        opacity: 1;
    }
}

@media screen and (orientation: landscape) {
    .intro-wrapper{
        min-height: calc(100vh - 40px);
    }
}

@media screen and (min-width: 600px) {
    .intro-wrapper{
        text-align: start;
        flex-direction: row;
    }
    
    .text{
        width: calc(55% - 2rem);
    }

    .text h1{
        font-size: 2.8rem;
    }

    .text p{
        font-size: 1.6rem;
    }

    .small-pic-div{
        display: none;
    }
    
    .large-pic-div{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45%;
    }
}
@media(min-width: 1024px) {
    
    .text h1{
        font-size: 4rem;
    }

    .text p{
        font-size: 2rem;
    }
}
@media(min-width:1366px){
    .text h1{
        font-size: 5rem;
    }

    .text p{
        font-size: 3rem;
    }
}