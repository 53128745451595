.wrapper {
    background-color: black;
    color: #eee;
    height: 100%;
}

.content{
    height: calc(100vh - 50px);
    overflow-y: scroll;
    scroll-behavior: smooth;
    padding: 0rem;
    margin: 0rem;
}

@media (prefers-reduced-motion){
    main {
        scroll-behavior: reduce;
    }
}

/* background: #000
secondary: #eee
accent: #099 */