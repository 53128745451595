.modal{
    position: fixed;
    top: 0vh;
    left: 0vw;
    width: 100vw;
    height: 100vh;
    z-index: 100;
    background-color: rgba(20, 20, 20, 0.95);
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: flex-start;
    align-items: center;
    text-align: center;
}

.close-formatting{
    width: 80vw;
    display: flex;
    justify-content: flex-end;
    align-items:flex-start;
}

.close-button{
    padding: 1rem;
    margin: 1rem;
    font-size: 2rem;
    cursor: pointer;
}

.downpage-link {
    color: #eee;
    font-weight: 500;
    text-decoration: none;
    border-bottom: 1px solid #099;
}