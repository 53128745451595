.title{
    font-size: 3rem;
}

.tech-wrapper{
    min-height: 250px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    font-size: 4rem;
}

.mongo{
    width: auto;
    height: 80px;
}

.express{
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    font-size: 1.6rem;
    font-weight: 100;
}

@media screen and (min-width: 600px) {
    .title{
        font-size: 4rem;
    }
    .express{
        font-size: 2.5rem;
    }
}
@media screen and (min-width: 1024px) {
    .title{
        font-size: 5rem;
    }
}