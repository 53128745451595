.link{
    border: 2px solid #eee;
    border-radius: 5px;
    background-color: #222;
    margin: 2px;
}

.link:hover{
    border: 4px solid #eee;
    margin: 0px;
}

.anchor{
    text-decoration: none;
    color: white;
    font-size: 1.25em;
    font-weight: 700;
}

.link-pic{
    display: block;
    width: 300px;
    height: 200px;
}

.title{
    padding: 0.5rem 0rem;
    margin: 0rem;
    border-top: 1px solid white;
}

@media screen and (min-width: 600px) {
    .link-pic{
        width: 360px;
        height: 240px;
    }
}
@media screen and (min-width: 1024px) {
    .link-pic{
        width: 450px;
        height: 300px;
    }
}