.title{
    font-size: 3rem;
}

.contacts{
    display: flex;
    flex-flow: wrap;
    justify-content: center;
    align-items: center;
}

.child{
    display: flex;
    align-items: center;
    margin: 0rem 1rem;
    height: 100px;
}

.brand{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    font-size: 3rem;
    color: #099;
    text-decoration: none;
}

@media screen and (min-width: 600px){
    .title{
        font-size: 4rem;
    }
    .child{
        margin: 0rem 2rem;
    }
    .brand{
        font-size: 4rem;
    }
}
@media screen and (min-width: 1024px){
    .title{
        font-size: 5rem;
    }
    .brand{
        font-size: 5rem;
    }
}