.nav-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0;
    padding: 0rem 1rem;
    position: sticky;
    top: 0px;
    z-index: 1;
    height: 49px;
    width: calc(100% - 2rem);
    background-color: black;
    border-bottom: 1px solid #099;
  }

.nav-bar a {
  color: white;
  text-decoration: none;
}

.nav-bar a:hover{
  text-shadow: #eee 1px 1px 5px;
}

.home{
  display: flex;
  justify-content: flex-start;
  min-width: 40%;
  font-size: 1rem;
  font-weight: 700;
}

.modal-icon-div{
  display: flex;
  font-size: 1.25rem;
  cursor: pointer;
}

.downpage-links{
  display: none;
}

@media screen and (min-width: 600px) {
  .nav-bar{
    padding: 0rem 2rem;
    width: calc(100% - 4rem);
  }
  .home{
    font-size: 1.5rem;
  }

  .modal-icon-div{
    display: none;
  }

  .downpage-links{
    display: flex;
    justify-content: space-between;
    width: 60%;
    font-size: 1rem;
  }
}
@media screen and (min-width: 1024px){
  .home{
    font-size: 2rem;
  }
  .downpage-links{
    font-size: 1.5rem;
  }
}
@media screen and (min-width: 1366px){
  .home{
    font-size: 2.5rem;
  }
  .downpage-links{
    font-size: 2rem;
  }
}