.title{
    font-size: 3rem;
}

.project-list{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.project{
    display: inline;
    margin: 0.5rem;
}

@media screen and (min-width: 600px) {
    .title{
        font-size: 4rem;
    }
}
@media screen and (min-width: 1024px) {
    .title{
        font-size: 5rem;
    }
}