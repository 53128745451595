.card{
    min-height:200px;
    text-align: center;
    margin: 1rem 0.5rem 3rem 0.5rem;
}

@media screen and (min-width: 600px) {
    .card{
        margin: 2rem 1rem 4rem 1rem;
    }
}
@media screen and (min-width: 1024px) {
    .card{
        margin: 4rem 1rem 8rem 1rem;
    }
}