.tech{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 100px;
    background-color: black;
    border: 1px solid #099;
    border-radius: 5px;
    margin: 0.5rem;
}

@media screen and (min-width: 600px){
    .tech{
        height: 150px;
        width: 150px;
    }
}
@media screen and (min-width: 1024px){
    .tech{
        height: 180px;
        width: 180px;
    }
}
@media screen and (min-width: 1366px){
    .tech{
        height: 225px;
        width: 225px;
    }
}